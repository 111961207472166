<template>
  <span>
      <b-dropdown position="is-bottom-right"
                  :mobile-modal="false"
                  v-show="showApplyForDocument"
                  append-to-body
                  aria-role="list"
                  trap-focus :disabled="isLoading">
        <template #trigger>
          <b-button class="icon-button dropdown-button apply-ai-generated-text"
                    :title="$t(`menubar.aiTextHover`)">
            <i class="exi exi-ai-generated"/>
            {{ $t(`menubar.aiTextTitle`) }}
          </b-button>
        </template>
        <b-dropdown-item key="applyForBlock"
                         aria-role="listitem"
                         v-show="showApplyForBlock"
                         @click="applyForBlock()">
          {{ $t(`menubar.applyForBlock`) }}
        </b-dropdown-item>
        <b-dropdown-item key="applyForDocument"
                         aria-role="listitem"
                         v-show="showApplyForDocument"
                         @click="applyForDocument()">
          {{ $t(`menubar.applyForDocument`) }}
        </b-dropdown-item>
      </b-dropdown>
  </span>
</template>

<script lang="ts">
import {Component, Inject, Prop, toNative, Vue, Watch} from 'vue-facing-decorator';
import EditorModule from '@/store/modules/EditorModule';
import {NamedEditor, SaveChangesFn} from '@/components/ApplicationEditor.vue';
import {useDefaultErrorHandling} from '@/errorHandling';
import {findNodeByGuid, findNodeRangeOfGuid} from '@/components/applicationEditor/utils/node.util';
import AiAssistantModule from '@/store/modules/AiAssistantModule';
import {containsMark, removeMarks} from '@/components/applicationEditor/utils/mark.util';

/**
 * Component to add buttons for all semantic types that may be added to the current active node or it's parents
 */
@Component
class ApplyAiGeneratedText extends Vue {
  @Inject({from: 'applicationEditor.saveChanges'}) saveEditorChangesFn!: SaveChangesFn;
  @Prop({required: true}) editor!: NamedEditor;

  private showApplyForBlock: boolean = false;
  private showApplyForDocument: boolean = false;

  private showApplyForDocumentCoroutine: number | null = null;
  private showApplyForBlockCoroutine: number | null = null;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  get selectedBlockGuid(): string | null {
    return AiAssistantModule.lastSelectedBlock;
  }

  mounted() {
    this.editor.on("update", this.calcShowApplyForDocument);
  }

  unmounted() {
    this.editor.off("update", this.calcShowApplyForDocument);
  }

  @Watch('selectedBlockGuid', {immediate: true})
  onLastSelectedBlockChange() {
    this.calcShowApplyForBlock();
  }

  public calcShowApplyForBlock() {
    if (this.showApplyForBlockCoroutine) {
      clearTimeout(this.showApplyForBlockCoroutine);
      this.showApplyForBlockCoroutine = null;
    }
    this.showApplyForBlockCoroutine = setTimeout(() => {
      if (!this.editor) {
        this.showApplyForBlock = false;
        return;
      }
      this.showApplyForBlock = (() => {
        if (!AiAssistantModule.lastSelectedBlock) {
          return false;
        }
        const node = findNodeByGuid(this.editor.state.doc, AiAssistantModule.lastSelectedBlock);

        if (!node) {
          return false;
        }
        return containsMark(node, [this.editor.schema.marks.aiGenerated]);
      })();
    }, 500);
  }

  public calcShowApplyForDocument() {
    if (this.showApplyForDocumentCoroutine) {
      clearTimeout(this.showApplyForDocumentCoroutine);
      this.showApplyForDocumentCoroutine = null;
    }
    this.showApplyForDocumentCoroutine = setTimeout(() => {
      if (!this.editor) {
        this.showApplyForDocument = false;
        return;
      }
      this.showApplyForDocument = containsMark(this.editor.state.doc, [this.editor.schema.marks.aiGenerated]);
    }, 500);
  }

  applyForBlock(): boolean {
    if (!this.selectedBlockGuid) {
      return false;
    }
    const {state} = this.editor;
    const nodeRange = findNodeRangeOfGuid(state.doc, this.selectedBlockGuid);

    if (!nodeRange) {
      return false;
    }
    const {view} = this.editor;
    const {tr} = state;
    const markType = state.schema.marks.aiGenerated;

    removeMarks(tr, nodeRange.start, nodeRange.end, [markType]);
    view.dispatch(tr);

    this.saveEditorChangesFn(false, AiAssistantModule.applyAiGeneratedText)
      .catch(useDefaultErrorHandling);
    AiAssistantModule.setAiGeneratedGuidForEditor(this.selectedBlockGuid)

    return true;
  }

  applyForDocument() {
    const {view, state} = this.editor;
    const {tr} = state;

    const docStart = 0;
    const docEnd = state.doc.content.size;
    const markType = state.schema.marks.aiGenerated;

    removeMarks(tr, docStart, docEnd, [markType]);
    view.dispatch(tr);

    this.saveEditorChangesFn(false, AiAssistantModule.applyAiGeneratedText)
      .catch(useDefaultErrorHandling);

    return true;
  }
}

export default toNative(ApplyAiGeneratedText);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors.scss';

// Remove button border in any case
button.dropdown-button {
  border: 0 solid transparent !important;

  &:hover:enabled, &:focus:enabled {
    border: 0 solid transparent !important;
  }
}

.apply-ai-generated-text {
  color: $pengine-grey-dark-dark;

  &:hover {
    color: $pengine-blue-dark !important;
  }
}
</style>
