import {EditorState, Plugin, PluginKey, Selection, TextSelection, Transaction} from '@tiptap/pm/state';
import {
  AffectedNodes,
  cut,
  getAffectedTextNodes,
  handleReferenceSignMarker,
  insert,
  moveToPreviousBlockRequired
} from '@/store/util/editor.util';
import {NodeRange} from '@/components/applicationEditor/utils/node.util';
import {EditorView} from '@tiptap/pm/view';
import {Node, ResolvedPos, Schema} from '@tiptap/pm/model';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import {BrowserObject} from 'vue-detect-browser';
import {findNextTextPosition, SearchDirection} from '@/components/applicationEditor/utils/prosemirror.util';
import log from "loglevel";
import {AddMarkStep, RemoveMarkStep, ReplaceStep} from '@tiptap/pm/transform';
import EditorModule from '@/store/modules/EditorModule';

const UserInputPluginKey = new PluginKey('UserInputPlugin');

export class UserInputPlugin extends Plugin {

  private static debug = true;

  private static log = (error: boolean, ...messages: any[]) => {
    if(this.debug){
      console.log(messages);
    }
    if(error){
      log.error(messages);
    }
  }

  private static columnPos: number | null = null;

  private static lastKnownCoords: {left: number, right: number, top: number, bottom: number};

  private static caretMovementMessage = "Moving Caret Up/Down";

  constructor(browserInfo: BrowserObject, rerenderFunction: () => void) {
    super({
            key: UserInputPluginKey,
            props: {
              handleKeyDown(view: EditorView, event: KeyboardEvent) {
                return UserInputPlugin.handleKeyDown(view, event, browserInfo);

              },
              handleTextInput(view: EditorView, from: number, to: number, text: string) {
                return UserInputPlugin.handleTextInput(view, text);
              },
              handlePaste(view, event, slice) {
                // Handle explicitly if needed
                return false;
              },
              handleDrop(view, event, slice, moved) {
                return true;
              },
            },
            filterTransaction(tr: Transaction, state): boolean {
              const transactionIsWhitelisted = UserInputPlugin.isWhitelisted(tr, state);
              // There are cases in which prosemirror state and DOM diverge. Therefore, the document is rerendered when transactions are
              // declined
              if(!transactionIsWhitelisted) {
                // There are some changes in which characters are inserted, but the respective textInput-Event is not triggered
                // As we want these changes and there is no way of integrating them in the usual workflow, exceptions are added to the
                // transaction whitelisting. These exceptions should be as strict as possible to minimize the risk that structural
                // changes could make it through
                if(tr.steps && tr.steps.length > 0 && tr.steps[0]) {
                  if(tr.steps[0] instanceof ReplaceStep) {
                    const step = tr.steps[0] as ReplaceStep;
                    if(step.slice.content.size === (step.to - step.from) && step.slice.content.size === 1) {
                      return true;
                    }
                  }
                }
                rerenderFunction();
              } else {
                // console.log(tr);
              }
              return transactionIsWhitelisted;
            },
            // appendTransaction is used to check if a transaction has been processed which requires to reset the column position of the caret
            appendTransaction(transactions, oldState, newState): Transaction | null | undefined {
              if(oldState.selection.head !== newState.selection.head) {
                for (const transaction of transactions) {
                  if(transaction.getMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE) === UserInputPlugin.caretMovementMessage || transaction.getMeta(ProsemirrorTransactionMeta.REPLICATED_TRANSACTION)) {
                    return null;
                  }
                }
                // Resetting columnPos for up/down navigation
                UserInputPlugin.columnPos = null;
              }
              return null;
            },
    })
  }

  public static isWhitelisted(tr: Transaction, state: EditorState) {
    if (tr.steps.length === 0) {
      return true;
    }
    const updateFromBackend = tr.getMeta(ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND) != null;
    const rootBlockChange = tr.getMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE) === "ApplicationEditor - onRootBlockChange";
    const patentEngineAllowedTransformation = tr.getMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION) === true;
    const replicatedTransaction = tr.getMeta(ProsemirrorTransactionMeta.REPLICATED_TRANSACTION) === true;
    if (updateFromBackend || rootBlockChange || patentEngineAllowedTransformation || replicatedTransaction) {
      return true;
    } else {
      let allMarkSteps = true;
      tr.steps.forEach((step) => {
        if (!(step instanceof AddMarkStep) && !(step instanceof RemoveMarkStep)) {
          allMarkSteps = false;
        }
      })
      return allMarkSteps;
    }
  }

  private static handleTextInput(view: EditorView, text: string): boolean {
    let selection = view.state.selection;
    let tr = view.state.tr;
    const affectedNodes = getAffectedTextNodes(selection);

    if(!affectedNodes) {
      // Do nothing but return true such that no standard handling grips
      return true;
    }
    selection = affectedNodes.selection;
    // Single-Node edit
    if (affectedNodes.headNode?.node().eq(affectedNodes.anchorNode!.node())) {
      tr = insert(tr, new NodeRange(affectedNodes.headNode.node(), selection.from, selection.to), text);
    }
    // Multi-Node edit
    else if (affectedNodes.fromNode && affectedNodes.toNode && affectedNodes) {
      tr = this.deleteMultiNode(tr, affectedNodes.fromNode, selection, affectedNodes, affectedNodes.toNode);
      const startPos = tr.mapping.map(selection.from);
      const endPos = tr.mapping.map(affectedNodes.fromNode.end());
      tr = insert(tr, new NodeRange(affectedNodes.fromNode.node(), startPos, endPos), text);
      tr = tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(selection.from)));
    }
    tr.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
    tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, "UserInputPlugin - handleTextInput");
    this.validateSteps(tr);
    view.dispatch(tr);
    return true;
  }

  private static handleKeyDown(view: EditorView, event: KeyboardEvent, browserInfo: BrowserObject): boolean {
    // See if navigation handling is sufficient
    if(UserInputPlugin.navigationHandling(view, event, browserInfo)) {
      return true;
    }

    // else handle Backspace and Delete keys
    const handleableEvents = event.key === "Backspace" || event.key === "Delete" || event.key === "Enter";
    if (!handleableEvents) {
      return false;
    }

    let tr = view.state.tr;
    let selection = view.state.selection;

    const affectedNodes = getAffectedTextNodes(selection);
    if(!affectedNodes) {
      return false;
    }
    selection = affectedNodes.selection;

    if(event.key === "Enter" && selection.from === selection.to) {
      const pos = tr.mapping.map(selection.from);
      tr = UserInputPlugin.insertNewline(view.state.schema, pos, tr);
      tr = tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(selection.from)));
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, "UserInputPlugin - keyDownHandler");
      tr.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
      view.dispatch(tr);
      return true;
    }

    // selection inside single node
    if (affectedNodes.headNode?.node().eq(affectedNodes.anchorNode!.node())) {
      tr = UserInputPlugin.deleteSingleNode(selection, event, tr, affectedNodes.headNode);
    }
    // multi-node edit
    else {
      const fromNode = affectedNodes.fromNode;
      const toNode = affectedNodes.toNode;
      if (!(fromNode && toNode)) {
        return false;
      }
      tr = UserInputPlugin.deleteMultiNode(tr, fromNode, selection, affectedNodes, toNode);
    }

    if(event.key === "Enter") {
      const pos = tr.mapping.map(selection.from);
      tr = UserInputPlugin.insertNewline(view.state.schema, pos, tr);
    }
    tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, "UserInputPlugin - keyDownHandler");
    tr.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
    tr = tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map(selection.from)));
    this.validateSteps(tr);
    view.dispatch(tr);
    return true;
  }


  private static insertNewline(schema: Schema, pos: number, tr: Transaction): Transaction {
    const hardBreak = schema.nodes.hardBreak;
    tr = tr.replaceWith(pos, pos, hardBreak.create());
    tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'UserInputPlugin - createNewlineAtAnchor');
    tr.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
    return tr;
  }

  /**
   * If a deletion spans across multiple textBlockNodes, use this function to resolve this in multiple delete operations across the
   * textBlockNodes
   * @param tr
   * @param fromNode
   * @param selection
   * @param affectedNodes
   * @param toNode
   * @private
   */
  private static deleteMultiNode(tr: Transaction, fromNode: ResolvedPos, selection: Selection, affectedNodes: AffectedNodes, toNode: ResolvedPos) {
    // Delete starting with first node
    tr = cut(tr, new NodeRange(fromNode.node(), selection.from, fromNode.end()));
    // ... continue with nodes between
    affectedNodes.nodesBetween.forEach(node => {
      const startPos = tr.mapping.map(node.start());
      const endPos = tr.mapping.map(node.end());
      tr = cut(tr, new NodeRange(node.node(), startPos, endPos));
    })
    // ... and finally the last one
    const startPos = tr.mapping.map(toNode.start());
    const endPos = tr.mapping.map(selection.to);
    tr = cut(tr, new NodeRange(toNode.node(), startPos, endPos));
    return tr;
  }

  /**
   * Use this function if characters inside a single textBlockNode shall be removed
   * @param selection
   * @param event
   * @param tr
   * @param affectedNode
   * @private
   */
  private static deleteSingleNode(selection: Selection, event: KeyboardEvent, tr: Transaction, affectedNode: ResolvedPos): Transaction {
    const offset = selection.from === selection.to ? 1 : 0
    if (event.key === "Backspace") {
      return cut(tr, new NodeRange(affectedNode.node(), selection.from - offset, selection.to));
    } else {
      return cut(tr, new NodeRange(affectedNode.node(), selection.from, selection.to + offset));
    }
  }

  /**
   * Delegate incoming keypress to this function to check for matching handling of the events
   * @param view
   * @param event
   * @private
   */
  private static navigationHandling(view: EditorView, event: KeyboardEvent, browserInfo: BrowserObject) {
    if (handleReferenceSignMarker(view, event)) {
      return true;
    }
    const moveToPreviousBlockTransaction = moveToPreviousBlockRequired(view, event);
    if (moveToPreviousBlockTransaction) {
      view.dispatch(moveToPreviousBlockTransaction);
      return true;
    }
    if(event.key === "ArrowUp" || event.key === "ArrowDown") {
      return this.navigateThroughLines(view, event)
    }
    // This is disgusting, but necessary it seems.
    // Following scenarios are solved this way:
    // Chrome/Safari: ArrowDown/ArrowUp do sometimes nothing at all
    // Chrome/Safari: Shift+Arrow<Left/Right> does not proceed beyond block borders
    if(browserInfo.isChrome || browserInfo.isSafari) {
      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        UserInputPlugin.log(false, 'Doing chrome and safari workaround for keyhandling');
        this.handleArrowKeysForChromeAndSafari(view, event);
      }
    }
  }

  /**
   * Proof of concept method. However, our schema seems to be too loosely defined for this to trip
   * @param tr
   * @private
   */
  private static validateSteps(tr: Transaction) {
    const steps = tr.steps;
    let doc = tr.before;
    for (const step of steps) {
      const stepRes = step.apply(doc);
      //UserInputPlugin.log(false, stepRes);
      if(stepRes.failed) {
        //UserInputPlugin.log(true, stepRes);
        UserInputPlugin.log(true, "Detected invalid step:", step, "out of", steps, "in transaction", tr);
        return;
      }
      if(stepRes.doc === null) {
        UserInputPlugin.log(true, "Unexpected behaviour in validateSteps()!");
        return;
      }
      doc = stepRes.doc;
    }
  }

  private static handleShiftArrowRight(tr: Transaction, selection: Selection) {
    const { $head, $anchor } = selection;
    const nextPos = findNextTextPosition(tr.doc, $head.pos, SearchDirection.RIGHT, true);
    if(nextPos){
      tr.setSelection(TextSelection.create(tr.doc, $anchor.pos, nextPos));
    }
  }

  private static handleShiftArrowLeft(tr: Transaction, selection: Selection) {
    const { $head, $anchor } = selection;
    const nextPos = findNextTextPosition(tr.doc, $head.pos, SearchDirection.LEFT, true);
    if(nextPos){
      tr.setSelection(TextSelection.create(tr.doc, $anchor.pos, nextPos));
    }
  }

  private static handleArrowKeysForChromeAndSafari(view: EditorView, event: KeyboardEvent) {

    if (event.shiftKey && (event.key === 'ArrowRight' || event.key === 'ArrowLeft') && !event.ctrlKey) {
      const { state } = view;
      const { selection } = state;
      const tr = state.tr;

      if (event.key === 'ArrowRight') {
        UserInputPlugin.handleShiftArrowRight(tr, selection);
      } else if (event.key === 'ArrowLeft') {
        UserInputPlugin.handleShiftArrowLeft(tr, selection);
      }

      if (tr.docChanged || !tr.selection.eq(selection)) {
        tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'ApplicationEditor - setSelection');
        view.dispatch(tr);
      }
      event.preventDefault();
      return true; // Indicate that the event was handled
    }

    return false; // Indicate that the event was not handled
  }

  /**
   * Due to some issues with arrow-up/arrow-down behaviour in several browser, this function is supposed to replace the handling the
   * browser would have done.
   * Predicting the position directly upwards is not sufficient, also need to remember the column position since start
   * @param view
   * @param event
   * @private
   */
  private static navigateThroughLines(view: EditorView, event: KeyboardEvent) {
    if (!(event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === '1')) {
      return false;
    }
    const selection = view.state.selection;
    const direction = event.key === 'ArrowUp' ? -1 : 1;
    const startCoordinates = view.coordsAtPos(selection.head);
    // Check if lastKnownCoords match. In case the view port is changed (scroll, zoom, ...) the saved coordinates no longer hold
    if(this.columnPos === null || (this.lastKnownCoords && this.lastKnownCoords.left !== startCoordinates.left)) {
      this.columnPos = startCoordinates.left;
    }

    // Move line up or down
    const positionInNewLine = this.matchYCoordinates(view, startCoordinates.top, selection.head, direction);
    if(!positionInNewLine) {
      return false;
    }
    const updatedCoords = view.coordsAtPos(positionInNewLine);

    // Get as close as possible to the desired column position
    const targetPosition = this.matchXCoordinates(view, this.columnPos, updatedCoords.top, positionInNewLine, direction);
    if(targetPosition) {
      const anchor = event.shiftKey ? view.state.selection.anchor : targetPosition;
      let tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, anchor, targetPosition));
      tr = tr.scrollIntoView();
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, this.caretMovementMessage);
      this.lastKnownCoords = view.coordsAtPos(targetPosition);
      view.dispatch(tr);
      return true;
    }
    return false;
  }

  private static matchXCoordinates(view: EditorView, left: number, top: number, startingPos: number, direction: number) {
    // initial check for early return
    // as input should be the last character of the line then, return immediately
    const coords = view.coordsAtPos(startingPos);
    if(direction < 0 && left > coords.left) {
      return startingPos;
    }

    let minimumDistance = Math.abs(coords.left - left);
    let currentPos = startingPos;
    // Save which the last 'save' position was as currentPos - 1 may not have been in a TextBlock
    let lastSavePos = currentPos;
    for (let i = 0; i < 500; i++) {
      currentPos += direction;
      if(!this.posIsInsideDoc(view, currentPos)) {
        return;
      }
      if(this.posIsInTextBlock(view, currentPos)) {
        continue;
      }
      const currentCoords = view.coordsAtPos(currentPos);
      // keep the discrepancy of 5 for now; a shift of 4 seems to be normal when moving away from the line end
      if(Math.abs(currentCoords.top - top) > 5 * EditorModule.zoomLevel) {
        // Changed into a different line, abort
        return lastSavePos;
      }
      const currentDistance = Math.abs(currentCoords.left - left);
      if(currentDistance > minimumDistance) {
        // Getting worse than the best result so far, abort
        return lastSavePos;
      }
      lastSavePos = currentPos;
      minimumDistance = currentDistance;
    }
  }

  private static matchYCoordinates(view: EditorView, top: number, pos: number, direction: number) {
    let currentPos = pos;
    for(let i = 0; i < 500; i++) {
      currentPos += direction;
      if(!this.posIsInsideDoc(view, currentPos)) {
        return;
      }
      if(this.posIsInTextBlock(view, currentPos)) {
        continue;
      }
      const coords = view.coordsAtPos(currentPos);
      if((coords.top - top) * direction > 1) {
        return currentPos;
      }
    }
  }

  private static posIsInTextBlock(view: EditorView, pos: number) {
    const type = view.state.doc.resolve(pos).node().type.name;
    return type !== "textBlockNode";
  }

  private static posIsInsideDoc(view: EditorView, pos: number) {
    const documentSize = view.state.doc.nodeSize;
    return pos >= 0 && pos < documentSize - 1;
  }
}