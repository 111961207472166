import {axiosInstance, axiosLlmRequestInstance} from '@/api';
import {CompletionResult} from '@/api/models/completionResult.model';
import {AiTemplate} from '@/api/models/aiTemplate.model';
import CompletionResultModule from '@/store/modules/CompletionResultModule';
import {DynamicByteBuffer} from '@/components/applicationEditor/utils/DynamicByteBuffer.util';
import {InterpretAiTemplateRequestDto, InterpretAiTemplateResponseDto} from '@/api/models/aiAssisstant.model';
import {PatentEngineException} from '@/api/models/exception.model';
import {Llm, LlmAutoFillRequest, LlmRequest} from '@/api/models/llm.model';
import {UseCase} from '@/api/models/aiTemplateUseCase.model';
import {BlocksUpdatedVmUpdate, UpdateBlocksEvent} from '@/api/models/editor.model';
import AiAssistantModule from '@/store/modules/AiAssistantModule';
import EditorModule from '@/store/modules/EditorModule';
import {searchForBlockBySemanticType} from '@/store/util/editor.util';
import SpellcheckModule from '@/store/modules/SpellcheckModule';


/**
 * The connection to the backend endoints for the aiFeature are defined here
 * */
const AI_ASSISTANT_PATH = '/aiAssistant';

export const GetAllLlms = async (): Promise<Array<Llm>> => {
  const res = await axiosInstance.get(`${AI_ASSISTANT_PATH}/llms`);
  return res?.data as Array<Llm>;
}
export const SendLlmRequest = async (llmRequest: LlmRequest): Promise<CompletionResult> => {
  const res = await axiosLlmRequestInstance.post(`${AI_ASSISTANT_PATH}/chat/completion`, llmRequest);
  return res?.data;
}
export const UpdateRating = async (resultId: string, rating: number): Promise<CompletionResult> => {
  const res = await axiosInstance.put(`${AI_ASSISTANT_PATH}/completionResults/${resultId}/${rating}`);
  return res?.data;
}
export const GetAiTemplates = async (applicationDocumentGuid: string): Promise<Array<AiTemplate>> => {
  const res = await axiosInstance.get(`${AI_ASSISTANT_PATH}/templates?applicationDocument=${applicationDocumentGuid}`);
  return res?.data;
}

export const InterpretAiTemplate = async (interpretAiTemplateRequestDto: InterpretAiTemplateRequestDto): Promise<InterpretAiTemplateResponseDto> => {
  const res = await axiosLlmRequestInstance.post(`${AI_ASSISTANT_PATH}/interpretAiTemplate`, interpretAiTemplateRequestDto);
  return res?.data;
}

export const ApplyAiGeneratedText = async (event: UpdateBlocksEvent): Promise<BlocksUpdatedVmUpdate> => {
  const res = await axiosLlmRequestInstance.put(`${AI_ASSISTANT_PATH}/apply`, event);
  return res?.data;
}

export const AutoFillAiTemplates = async (llmAutoFillRequest: LlmAutoFillRequest): Promise<void> => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await fetch(
    `${baseUrl}${AI_ASSISTANT_PATH}/chat/streamingCompletion/autofill`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(llmAutoFillRequest)
    });

  if (!response.ok) {
    const errorResponse = await response.json().catch(() => null);
    if (errorResponse == null) {
      return Promise.reject(null);
    }
    const exception = errorResponse as PatentEngineException;
    return Promise.reject(exception.localizedMessage);
  }
  const reader = response.body!.getReader();
  return readCompletionResultStream(reader, UseCase.AUTO_FILL);
}

// TODO: Tests schreiben
export const SendLlmRequestStream = async (llmRequest: LlmRequest): Promise<void> => {
  const baseUrl = process.env.VUE_APP_API_URL;
  const response = await fetch(
    `${baseUrl}${AI_ASSISTANT_PATH}/chat/streamingCompletion`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(llmRequest)
    });

  if (!response.ok) {
    const errorResponse = await response.json().catch(() => null);
    if (errorResponse == null) {
      return Promise.reject(null);
    }
    const exception = errorResponse as PatentEngineException;
    return Promise.reject(exception.localizedMessage);
  }
  const reader = response.body!.getReader();
  return readCompletionResultStream(reader, UseCase.AI_ASSISTANT);
}

async function readCompletionResultStream(reader: ReadableStreamDefaultReader, useCase: UseCase): Promise<void> {
  const decoder = new TextDecoder('utf-8');
  const streamBuffer = new DynamicByteBuffer();
  const llmResults = new Map<string, CompletionResult>();
  let llmResult: CompletionResult | BlocksUpdatedVmUpdate | null = null;
  let vmUpdate: BlocksUpdatedVmUpdate | null = null;
  let done2 = false;

  while (!done2) {
    const {done, value} = await reader.read();
    done2 = done;
    if (done2) {
      return Promise.resolve();
    }
    streamBuffer.append(value);
    let objectLength = streamBuffer.readInt32BigEndian()
    while (objectLength !== null && !isNaN(objectLength) && streamBuffer.getLength() >= objectLength + 4) {
      streamBuffer.cutBytes(4);
      const chunk = streamBuffer.cutBytes(objectLength);
      llmResult = JSON.parse(decoder.decode(chunk)) as CompletionResult | BlocksUpdatedVmUpdate;
      objectLength = streamBuffer.readInt32BigEndian();

      // Fix: remember last results per block.
      if (llmResult !== null && llmResult != undefined) {
        if (!('applicationDocumentAffected' in llmResult)) {
          llmResults.set(llmResult.selectedBlockGuid, llmResult);
        } else {
          vmUpdate = llmResult;
        }
      }
    }
    if (useCase === UseCase.AI_ASSISTANT) {
      updateAiAssistantStreamingResult(llmResults);
    } else if (useCase === UseCase.AUTO_FILL) {
      updateAutoFillStreamingResult(llmResults);

      if (vmUpdate !== null) {
        CompletionResultModule.endAutoFillChunkedResult(vmUpdate);
        updateAutoFillSpellchecks(vmUpdate);
      }
    }
  }
  return Promise.resolve();
}

function updateAiAssistantStreamingResult(llmResults: Map<string, CompletionResult>) {
  for (const chunk of llmResults.values()) {
    CompletionResultModule.setAiAssistantChunkedResult(chunk);
  }
}

function updateAutoFillStreamingResult(llmResults: Map<string, CompletionResult>) {
  for (const chunk of llmResults.values()) {
    CompletionResultModule.setAutoFillChunkedResult({guid: chunk.selectedBlockGuid, responseText: chunk.responseText});
  }
}

/**
 * Identifies all decorations of the blocks that have been auto-filled by the use of the magic button.
 * The decorations of the previous content of these blocks have to be removed before the spellcheck for the new content can be triggered.
 * Otherwise, the new  decorations may occur at the wrong places in the block.
 * */
function updateAutoFillSpellchecks(vmUpdate: BlocksUpdatedVmUpdate) {
  const spellcheckCandidateGuids: string[] = [];

  if (!AiAssistantModule.semanticTypeOfCurrentAiTemplate) {
    return;
  }
  const rootBlock = searchForBlockBySemanticType(
    EditorModule.currentRootBlock!,
    AiAssistantModule.semanticTypeOfCurrentAiTemplate)?.parent;
  const rootBlockTypeless = rootBlock as any;
  const textBlocks = rootBlockTypeless.children!;

  for (const block of vmUpdate.blocks) {
    for (const textBlock of textBlocks) {
      for (const child of textBlock.children) {
        if (child.guid === block.guid) {
          const oldSpellchecks = (SpellcheckModule.spellingMistakes
            .filter(sm => (sm.logicalBlockGuid === textBlock.guid)));
          oldSpellchecks.forEach(el => spellcheckCandidateGuids.push(el.logicalBlockGuid));
        }
      }
    }
  }
  SpellcheckModule.removeMistakes(it => !spellcheckCandidateGuids.includes(it.logicalBlockGuid));

  for (const block of vmUpdate.blocks) {
    SpellcheckModule.addDelayedSpellcheckBlockRequest({logicalBlockGuid: block.guid, delayMs: 800});
  }
  AiAssistantModule.setSemanticTypeOfCurrentAiTemplate(null);
}