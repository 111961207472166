import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d8f22f9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  class: "document-editor-area",
  ref: "document-editor-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menubar = _resolveComponent("Menubar")!
  const _component_SpellcheckDictionaryManagement = _resolveComponent("SpellcheckDictionaryManagement")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!
  const _component_MenuBubble = _resolveComponent("MenuBubble")!
  const _component_SpellcheckOverlay = _resolveComponent("SpellcheckOverlay")!
  const _component_AiAssistantDialog = _resolveComponent("AiAssistantDialog")!
  const _component_ApplicationEditorView = _resolveComponent("ApplicationEditorView")!
  const _component_Pane = _resolveComponent("Pane")!
  const _component_Splitpanes = _resolveComponent("Splitpanes")!

  return (_openBlock(), _createElementBlock("div", {
    class: "applicationEditor",
    onMouseup: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.onDialogDragEnd && _ctx.onDialogDragEnd(...args))),
    onCopy: _cache[11] || (_cache[11] = ($event: any) => (this.copyListener($event))),
    ref: "applicationEditorRef"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.computedActiveEditor !== null)
        ? (_openBlock(), _createBlock(_component_Menubar, {
            key: 0,
            editor: _ctx.computedActiveEditor,
            applicationeditor: this
          }, null, 8, ["editor"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.computedActiveEditor !== null)
      ? (_openBlock(), _createBlock(_component_SpellcheckDictionaryManagement, {
          key: 0,
          class: "hover-button dialog-button",
          showButton: false,
          editor: _ctx.computedActiveEditor,
          ref: "spellcheckDictionaryManagementDialog"
        }, null, 8, ["editor"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ConfirmationDialog, {
      ref: "deleteAllWordsFromIgnoreListDialog",
      titleKey: "spellcheckDictionaryManagement.delete.title",
      questionKey: "deleteReferenceSign.question"
    }, null, 512),
    (_ctx.activeEditor !== null)
      ? _withDirectives((_openBlock(), _createBlock(_component_MenuBubble, {
          key: 1,
          editor: _ctx.activeEditor,
          afterCommandExecution: this.cancelSaveOnBlur
        }, null, 8, ["editor", "afterCommandExecution"])), [
          [_vShow, !_ctx.hideMenuBubble && !_ctx.isGenerateUnRedoLoading]
        ])
      : _createCommentVNode("", true),
    (_ctx.activeEditor !== null || true)
      ? (_openBlock(), _createBlock(_component_SpellcheckOverlay, {
          key: 2,
          editor: _ctx.activeEditor,
          locale: _ctx.locale,
          appDocGuid: _ctx.appDocGuid,
          ref: "spellcheckOverlay"
        }, null, 8, ["editor", "locale", "appDocGuid"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_AiAssistantDialog, {
      showing: _ctx.showAiDialog,
      onDialogDragged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDialogDragged($event))),
      onDialogDragStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDialogDragStart($event))),
      onDialogDragEnd: _ctx.onDialogDragEnd,
      "dialog-postion": _ctx.aiDialogPositionFunction,
      editor: _ctx.activeEditor,
      locale: _ctx.locale,
      appDocGuid: _ctx.appDocGuid,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onExitButtonClicked())),
      ref: "aiAssistantDialog"
    }, null, 8, ["showing", "onDialogDragEnd", "dialog-postion", "editor", "locale", "appDocGuid"]), [
      [_vShow, _ctx.showAiDialog]
    ]),
    _createElementVNode("div", _hoisted_2, [
      (this.editorSplitMode === _ctx.EditorSplitMode.ONE)
        ? (_openBlock(), _createBlock(_component_ApplicationEditorView, {
            "is-active": true,
            "show-as-active": false,
            editor: this.editorComputed,
            "hide-menu-bubble": this.hideMenuBubble,
            locale: this.locale,
            "app-doc-guid": this.appDocGuid,
            "pos-of-current-block": this.posOfCurrentBlock,
            key: this.editorsVueKey,
            onEditorScrolled: this.onScroll,
            onReady: _ctx.onEditorViewReady,
            onAiButtonClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onAiButtonClicked($event))),
            aiDialogActive: this.showAiDialog,
            ref: "editor1Ref"
          }, null, 8, ["editor", "hide-menu-bubble", "locale", "app-doc-guid", "pos-of-current-block", "onEditorScrolled", "onReady", "aiDialogActive"]))
        : (this.editorSplit === _ctx.TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY)
          ? (_openBlock(), _createBlock(_component_Splitpanes, {
              key: 1,
              horizontal: true,
              onResized: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onResized($event)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Pane, {
                  size: this.editorSplitRatio
                }, {
                  default: _withCtx(() => [
                    (this.editorComputed !== null)
                      ? (_openBlock(), _createBlock(_component_ApplicationEditorView, {
                          editor: this.editorComputed,
                          "is-active": this.computedActiveEditorName === this.editorComputed.editorName,
                          "show-as-active": this.computedActiveEditorName === this.editorComputed.editorName,
                          "hide-menu-bubble": this.hideMenuBubble,
                          locale: this.locale,
                          "app-doc-guid": this.appDocGuid,
                          "pos-of-current-block": this.posOfCurrentBlock,
                          key: this.editorsVueKey,
                          onEditorScrolled: this.onScroll,
                          onReady: _ctx.onEditorViewReady,
                          onAiButtonClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onAiButtonClicked($event))),
                          aiDialogActive: this.showAiDialog,
                          ref: "editor1Ref"
                        }, null, 8, ["editor", "is-active", "show-as-active", "hide-menu-bubble", "locale", "app-doc-guid", "pos-of-current-block", "onEditorScrolled", "onReady", "aiDialogActive"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["size"]),
                _createVNode(_component_Pane, {
                  size: 100.0 - this.editorSplitRatio
                }, {
                  default: _withCtx(() => [
                    (this.editor2Computed !== null)
                      ? (_openBlock(), _createBlock(_component_ApplicationEditorView, {
                          editor: this.editor2Computed,
                          "is-active": this.computedActiveEditorName === this.editor2Computed.editorName,
                          "show-as-active": this.computedActiveEditorName === this.editor2Computed.editorName,
                          "hide-menu-bubble": this.hideMenuBubble,
                          locale: this.locale,
                          "app-doc-guid": this.appDocGuid,
                          "pos-of-current-block": this.posOfCurrentBlock,
                          key: this.editorsVueKey,
                          onEditorScrolled: this.onScroll,
                          onReady: _ctx.onEditorViewReady,
                          onAiButtonClicked: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onAiButtonClicked($event))),
                          aiDialogActive: this.showAiDialog,
                          ref: "editor2Ref"
                        }, null, 8, ["editor", "is-active", "show-as-active", "hide-menu-bubble", "locale", "app-doc-guid", "pos-of-current-block", "onEditorScrolled", "onReady", "aiDialogActive"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["size"])
              ]),
              _: 1
            }))
          : (this.editorSplit === _ctx.TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY)
            ? (_openBlock(), _createBlock(_component_Splitpanes, {
                key: 2,
                onResized: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onResized($event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Pane, {
                    size: this.editorSplitRatio
                  }, {
                    default: _withCtx(() => [
                      (this.editorComputed !== null)
                        ? (_openBlock(), _createBlock(_component_ApplicationEditorView, {
                            editor: this.editorComputed,
                            "is-active": this.computedActiveEditorName === this.editorComputed.editorName,
                            "show-as-active": this.computedActiveEditorName === this.editorComputed.editorName,
                            "hide-menu-bubble": this.hideMenuBubble,
                            locale: this.locale,
                            "app-doc-guid": this.appDocGuid,
                            "pos-of-current-block": this.posOfCurrentBlock,
                            key: this.editorsVueKey,
                            onEditorScrolled: this.onScroll,
                            onReady: _ctx.onEditorViewReady,
                            onAiButtonClicked: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onAiButtonClicked($event))),
                            aiDialogActive: this.showAiDialog,
                            ref: "editor1Ref"
                          }, null, 8, ["editor", "is-active", "show-as-active", "hide-menu-bubble", "locale", "app-doc-guid", "pos-of-current-block", "onEditorScrolled", "onReady", "aiDialogActive"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["size"]),
                  _createVNode(_component_Pane, {
                    size: 100.0 - this.editorSplitRatio
                  }, {
                    default: _withCtx(() => [
                      (this.editor2Computed !== null)
                        ? (_openBlock(), _createBlock(_component_ApplicationEditorView, {
                            editor: this.editor2Computed,
                            "is-active": this.computedActiveEditorName === this.editor2Computed.editorName,
                            "show-as-active": this.computedActiveEditorName === this.editor2Computed.editorName,
                            "hide-menu-bubble": this.hideMenuBubble,
                            locale: this.locale,
                            "app-doc-guid": this.appDocGuid,
                            "pos-of-current-block": this.posOfCurrentBlock,
                            key: this.editorsVueKey,
                            onEditorScrolled: this.onScroll,
                            onReady: _ctx.onEditorViewReady,
                            onAiButtonClicked: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onAiButtonClicked($event))),
                            aiDialogActive: this.showAiDialog,
                            ref: "editor2Ref"
                          }, null, 8, ["editor", "is-active", "show-as-active", "hide-menu-bubble", "locale", "app-doc-guid", "pos-of-current-block", "onEditorScrolled", "onReady", "aiDialogActive"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["size"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
    ], 512)
  ], 544))
}